/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import StaticLayout from '../components/StaticLayout';
import BlockView from '../components/BlockView';
import './about-us.module.css';

const AboutUs = ({ data }) => {
  const block = JSON.parse(data.v5.block.data);

  return (
    <StaticLayout>
      <div styleName="root">
        <BlockView blocks={block} />
      </div>
    </StaticLayout>
  );
};

AboutUs.propTypes = {
  data: PropTypes.object.isRequired,
};

export const query = graphql`
  query AboutUsQuery {
    v5 {
      block(name: "about") {
        data
      }
    }
  }
`;

export default AboutUs;
